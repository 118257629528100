import { createStore } from 'vuex'
import useStorage from "@/utils/useStorage";
import {http} from "@/api";
import {ElMessage} from "element-plus";
import router from "@/router";
const storage = useStorage()
const userInfoKey = 'askjina_user_userInfo'
const tokenKey = 'askjina_user_token'
export default createStore({
  state: {
      userInfo: storage.getItem(userInfoKey) || {},
      token: storage.getItem(tokenKey) || ''
  },
  getters: {
      userInfo: state => state.userInfo,
      token: state => state.token
  },
  mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
            storage.setItem(userInfoKey, userInfo);
        },
        setToken(state, token) {
            state.token = token;
            storage.setItem(tokenKey, token);
        },
        logout(state) {
            state.userInfo = {};
            state.token = '';
            storage.removeItem(userInfoKey);
            storage.removeItem(tokenKey);
            router.replace('/user/login')
        }
  },
  actions: {
      login({ commit }, userInfo) {
          return new Promise((resolve, reject) => {
              http.login(userInfo).then(res => {
                      if (res.code === 200) {
                          commit('setUserInfo', res.data);
                          commit('setToken', res.data.token);
                          resolve(res);
                      } else {
                            ElMessage.error(res.msg)
                            reject(res)
                      }
              }).catch(err => {
                    reject(err)
              })
          })
      },
      register({ commit }, userInfo) {
          return new Promise((resolve, reject) => {
              http.register(userInfo).then(res => {
                  if (res.code === 200) {
                      commit('setUserInfo', res.data);
                      commit('setToken', res.data.token);
                      resolve(res);
                  } else {
                        ElMessage.error(res.msg)
                        reject(res)
                  }
              }).catch(err => {
                  reject(err)
              })
          })
      }
  },
  modules: {
  }
})
