import axios from 'axios';
import { ElMessage } from 'element-plus';
import store from '@/store';
// 创建axios实例
const service = axios.create({
    baseURL: '/api', // api的base_url，根据你的项目配置
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么，比如添加token到headers中
        console.log(config)
        if (!config.headers.Authorization) {
            config.headers['Authorization'] = store.getters.token;
        }
        return config;
    },
    error => {
        // 处理请求错误
        console.error('Request Error:', error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 如果返回状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        const res = response.data;
        if (res.code === 200) {
            return res
        } else {
            ElMessage.error(res.msg)
        }
        return Promise.reject(new Error(res.msg || 'Error'));
    },
    error => {
        // 处理响应错误
        if (error.code === 500) {
            ElMessage.error(error.msg)
        }
        console.error('Response Error:', error); // for debug
        return Promise.reject(error);
    }
);

export default service; // 导出axios实例，以便在项目中其他文件使用
