import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
  {
    path: '/',
    redirect: '/chat'
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/index.vue')
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    name: 'chat',
    path: '/chat',
    component: () => import('@/views/chat/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    const token = store.getters.token
    if (!token) {
      // 创建token 设置token
      let _token = (Math.random() * 100000000000000000000).toString(36).substring(5, 16) + '' + new Date().getTime()
      store.commit('setToken', _token)
    }
    next()
})
export default router
