/**
 * 路由拦截器
 */
import router from './router'
import store from './store'
router.beforeEach((to, from, next) => {
    /**
     * partners.askjina.com
     * 8月25日后，所有用户都需要登录
     */
    if (to.path === '/error') {
        next()
    } else {
        if (new Date() > new Date('2024-09-15')) {
            next('/error')
        } else {
            next()
        }
    }
})
